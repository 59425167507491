import React from "react";
import { graphql } from "gatsby";
import GraphQLErrorList from "../components/graphql-error-list";
import Seo from "../components/seo";
import Layout from "../containers/layout";

import NewsList from "../components/news-list";

export const query = graphql`
  query CategoryQuery($skip: Int!, $limit: Int!, $id: String!, $slug: String!) {
    newsPage: sanityNewsPage {
      title
    }

    currentCat: sanityCategory(id: { eq: $id }) {
      id
      title
      description
      slug {
        current
      }
    }

    news: allSanityNews(
      sort: { fields: [publishedOn], order: DESC }
      filter: { category: { slug: { current: { eq: $slug } } } }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          id
          title
          category {
            title
            slug {
              current
            }
          }
          publishedOn
          slug {
            current
          }
          externalLink {
            text
            url
          }
          documentLink {
            text
            document {
              asset {
                url
                originalFilename
              }
            }
          }
        }
      }
    }

    cats: allSanityCategory(
      sort: { fields: [order], order: ASC }
      filter: { slug: { current: { ne: null } } }
    ) {
      edges {
        node {
          id
          title
          slug {
            current
          }
        }
      }
    }
  }
`;

const CategoryTemplate = (props) => {
  const { data, pageContext, errors } = props;
  const { currentPage, numPages } = pageContext;

  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    );
  }

  const newsPage = (data || {}).newsPage;
  const currentCat = (data || {}).currentCat;
  const news = (data || {}).news.edges.filter(post => (post.node.category.slug.current === currentCat.slug.current));
  const cats = (data || {}).cats;

  return (
    <Layout>
      <Seo
        title={newsPage.title + " | " + currentCat.title}
        description={currentCat.description}
      />

      {news && <NewsList news={news} cats={cats} category={currentCat.slug.current} currentPage={currentPage} numPages={numPages} />}
    </Layout>
  );
};

export default CategoryTemplate;
